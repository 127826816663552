const moment = require("moment");

export const dateDescending = (a, b) => {
  //   let dateA = new Date(a.date);
  //   let dateB = new Date(b.date);
  //   return dateB - dateA;
  let gpmStartDateA = new Date(moment(a.gpmStartDate, "DD MM YYYY").format());
  let gpmStartDateB = new Date(moment(b.gpmStartDate, "DD MM YYYY").format());
  return gpmStartDateB - gpmStartDateA;
};

export const dateAscending = (a, b) => {
  //   let dateA = new Date(a.date);
  //   let dateB = new Date(b.date);
  //   return dateA - dateB;

  let gpmStartDateA = new Date(moment(a.gpmStartDate, "DD MM YYYY").format());
  let gpmStartDateB = new Date(moment(b.gpmStartDate, "DD MM YYYY").format());
  return gpmStartDateA - gpmStartDateB;
};

export const dateDescendingNormal = (a, b) => {
  let dateA = new Date(a.date);
  let dateB = new Date(b.date);
  return dateB - dateA;
};

export const dateAscendingNormal = (a, b) => {
  let dateA = new Date(a.date);
  let dateB = new Date(b.date);
  return dateA - dateB;
};

export const jiraIdDescending = (a, b) => {
  let idA = a.id.replace(/[^\d]/g, "");
  let idB = b.id.replace(/[^\d]/g, "");
  return idB - idA;
};

export const jiraIdAscending = (a, b) => {
  let idA = a.id.replace(/[^\d]/g, "");
  let idB = b.id.replace(/[^\d]/g, "");
  return idA - idB;
};
