import React, { Fragment, useState, useEffect } from "react";
import { formatter } from "../helpers/formatMoney.js";
import moment from "moment";
import { SearchIcon, XCircleIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { Popover, Transition } from "@headlessui/react";
import _, { filter, isEmpty } from "lodash";
import AsideCard from "./AsideCard";
import SkeletonLoader from "./SkeletonLoader.js";
import SortAscending from "../assets/svgs/sort-ascending.svg";
import SortDescending from "../assets/svgs/sort-descending.svg";
import { RefreshIcon, DownloadIcon } from '@heroicons/react/solid';
import { Parser } from 'json2csv';
import { CSVLink } from "react-csv";

const exportFields = ['index', 'value', 'code', 'customerEmail', 'expiry', 'status', 'message'];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const statusStyles = {
  Completed: "bg-green-100 text-green-800",
  Deployed: "bg-green-100 text-green-800",
  "In Progress": "bg-yellow-100 text-yellow-800",
  "PM Input Required": "bg-yellow-100 text-yellow-800",
  "Quality Assurance": "bg-yellow-100 text-yellow-800",
  Cancelled: "bg-red-100 text-red-800",
  Other: "bg-gray-300 text-gray-800",
};

const Table = ({
  loaded,
  data,
  sortType,
  toggleButton,
  gpmManaged,
  ledgerDataTopUps,
  ledgerDataGPMManagement,
  ledgerDataAdManagement,
  ledgerDataEomBalance,
  ledgerDataOther,
  ledgerLoaded,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [statusLists, setStatusLists] = useState({});
  const [monthFilter, setMonthFilter] = useState("");
  const [yearFilter, setYearFilter] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  // console.log("TABLEEEE", ledgerDataOther);
  useEffect(() => {
    clearFilter();
  }, []);
  useEffect(() => {
    let totalCost = 0;

    filteredData.forEach((data) => {
      if (data.amount) {
        totalCost += data.amount;
      }
    });
    setTotalCost(totalCost);
  }, [monthFilter, yearFilter, statusLists]);

  const jiraUrl = "https://elephant-room.atlassian.net/browse";

  const filteredData = (searchValue || monthFilter !== "" || yearFilter !== ""
    ? data.filter(({ name, id, gpmStartDate, amount }) => {
      const startDateFormatted = gpmStartDate ? moment(gpmStartDate, "DD MM YYYY").format("DD MMM YYYY") : null;
      return (
        (name.toLowerCase().includes(searchValue) || id.toLowerCase().includes(searchValue)) &&
        startDateFormatted.includes(monthFilter) &&
        startDateFormatted.includes(yearFilter)
      );
    })
    : data
  )?.filter((row) => {
    if (!isEmpty(statusLists)) {
      return statusLists[row.status];
    }
    return true;
  });

  const exportData = filteredData.map(ticket => {
    return {
      ticketId: ticket.id,
      ticketName: ticket.name,
      cost: ticket.amount,
      proBono: ticket?.labels?.includes("probono") ? true : false,
      status: ticket.status,
      // ticketDate: moment(ticket.date).format("DD/MM/YYYY"),
      startDate: ticket.gpmStartDate,
      link: `${jiraUrl}/${ticket.id}`
    }
  })

  let statusArray = [];

  data?.forEach((item) => {
    let i = statusArray.findIndex((x) => x.status === item.status);
    if (i <= -1) {
      statusArray.push({ status: item.status });
    }
  });

  const changeHandler = (e) => {
    const value = e.target.checked;
    const name = e.target.name;
    setStatusLists((prevState) => {
      const newState = { ...prevState };
      if (value === false) {
        delete newState[name];
      } else {
        newState[name] = value;
      }
      return newState;
    });
  };

  const clearFilter = () => {
    setMonthFilter("");
    setYearFilter("");
    setStatusLists({});
  };

  const numberOfFilteredStatus = Object.keys(statusLists).filter((key) => statusLists[key]);

  const buttonHandler = (chosenSort) => {
    if (chosenSort === "toggleSortDate") {
      if (sortType === "dateAscending") {
        toggleButton("dateDescending");
      } else {
        toggleButton("dateAscending");
      }
    }

    if (chosenSort === "toggleJiraIdSort") {
      if (sortType === "jiraIdAscending") {
        toggleButton("jiraIdDescending");
      } else {
        toggleButton("jiraIdAscending");
      }
    }
  };

  if (!loaded) {
    return (
      <SkeletonLoader ledgerDataTopUps={ledgerDataTopUps} ledgerDataGPMManagement={ledgerDataGPMManagement} ledgerDataAdManagement={ledgerDataAdManagement} ledgerLoaded={ledgerLoaded} />
    );
  }
  if ((loaded && data?.length === 0) || (loaded && data === undefined)) return null;

  const updateMonthFilter = (e) => {
    setMonthFilter(e.target.value);
  };
  const updateYearFilter = (e) => {
    setYearFilter(e.target.value);
  };

  const yearOptions = () => {
    let currentYear = new Date().getFullYear();
    let earliestYear = currentYear - 2;
    let options = [];

    for (let i = earliestYear; i <= currentYear; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return options;
  };

  return (
    <main className="flex-1 relative overflow-y-auto focus:outline-none">
      <div className="py-8 xl:py-10">
        <div className="filter-parent flex flex-col mb-6 px-4 py-5 sm:p-6 bg-white shadow rounded-lg overflow-hidden">
          <div>
            <dt className="text-sm font-medium text-gray-500 truncate">Filter</dt>
          </div>
          <div className="filter-container flex flex-row">
            <div className="mt-4 text-left text-base font-bold">
              <label className="text-base font-medium text-gray-900">
                Month:
                <select
                  className="border border-solid border-gray-300 ml-3 text-black
      rounded"
                  value={monthFilter}
                  onChange={updateMonthFilter}
                >
                  <option value="" defaultValue={""}>
                    All
                  </option>
                  <option value="Jan">Jan</option>
                  <option value="Feb">Feb</option>
                  <option value="Mar">Mar</option>
                  <option value="Apr">Apr</option>
                  <option value="May">May</option>
                  <option value="Jun">Jun</option>
                  <option value="Jul">Jul</option>
                  <option value="Aug">Aug</option>
                  <option value="Sep">Sep</option>
                  <option value="Oct">Oct</option>
                  <option value="Nov">Nov</option>
                  <option value="Dec">Dec</option>
                </select>
              </label>
            </div>
            <div className="mt-4 ml-4 text-left text-base font-bold">
              <label className="text-base font-medium text-gray-900">
                Year:
                <select
                  className="border border-solid border-gray-300 ml-3 text-black
      rounded"
                  value={yearFilter}
                  onChange={updateYearFilter}
                >
                  <option value="" defaultValue={""}>
                    All
                  </option>

                  {yearOptions()}
                </select>
              </label>
            </div>
            <div className="mt-4 ml-4 flex items-center">
              <button
                onClick={clearFilter}
                className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-indigo-600 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                Clear
              </button>

              <CSVLink
                disabled={!exportData.length}
                data={exportData}
                onClick={event => {
                  if (!exportData.length) return false;
                }}
                filename={"elephantroom_billing.csv"}
                target="_blank"
              >
                <button
                  type="button"
                  className="inline-flex items-center py-2.5 px-5 mr-2 mb-2  border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                >
                  <DownloadIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Export to CSV
                </button>
              </CSVLink>

            </div>
          </div>
        </div>

        <div className="md:grid md:grid-cols-4">
          {/* jira tickets */}
          <div className="md:col-span-3 md:pr-1">
            <div className="">
              <div className="flex flex-col">
                <div className="align-middle min-w-full shadow sm:rounded-lg">
                  <table className="min-w-full divide-y  divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-2 sm:px-4 py-4 bg-yellow-500 text-left text-base font-extrabold text-white whitespace-nowrap max-w-max uppercase">
                          <div
                            className="flex flex-1 justify-end items-end cursor-pointer hover:text-indigo-800"
                            onClick={() => buttonHandler("toggleJiraIdSort")}
                          >
                            Jira ID
                            {sortType.includes("Descending") ? (
                              <SortDescending className="h-5 w-5 inline-block" />
                            ) : (
                              <SortAscending className="h-5 w-5 inline-block" />
                            )}
                          </div>
                        </th>
                        <th className="px-2 sm:px-4 py-4 bg-yellow-500 text-left text-base font-extrabold text-white uppercase">Summary</th>
                        <th className="hidden md:table-cell px-4 py-4 bg-yellow-500 text-right text-base font-extrabold text-white uppercase">
                          Cost
                        </th>
                        <th className="hidden px-4 py-4 bg-yellow-500 text-left lg:table-cell">
                          <Popover.Group className="hidden lg:flex justify-center">
                            <Popover as="div" className="relative z-10 inline-block text-left">
                              <div>
                                <Popover.Button className="group flex items-center justify-center text-base font-extrabold text-white uppercase hover:text-indigo-800">
                                  <span>Status</span>
                                  {numberOfFilteredStatus.length > 0 ? (
                                    <span className="ml-1.5 rounded py-0.5 px-1 bg-gray-200 text-xs font-semibold bg-indigo-50 text-indigo-800 tabular-nums">
                                      {numberOfFilteredStatus.length}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  <ChevronDownIcon
                                    className="fill-current text-white flex-shrink-0 -mr-1 h-5 w-5 group-hover:text-indigo-800"
                                    aria-hidden="true"
                                  />
                                </Popover.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Popover.Panel className="origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <form className="space-y-4">
                                    {statusArray.map(({ status }) => (
                                      <div key={status} className="flex items-center">
                                        <input
                                          id={`filter-${status}`}
                                          name={status}
                                          checked={Boolean(statusLists[status])}
                                          type="checkbox"
                                          className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                                          onChange={changeHandler}
                                        />
                                        <label
                                          htmlFor={`filter-${status}`}
                                          className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap"
                                        >
                                          {status}
                                        </label>
                                      </div>
                                    ))}
                                  </form>
                                </Popover.Panel>
                              </Transition>
                            </Popover>
                            {/* ))} */}
                          </Popover.Group>
                        </th>
                        <th className="px-2 sm:px-4 py-4 bg-yellow-500 text-base font-extrabold text-white uppercase">
                          <div
                            className="flex flex-1 justify-end items-end cursor-pointer hover:text-indigo-800 whitespace-nowrap"
                            onClick={() => buttonHandler("toggleSortDate")}
                          >
                            Start Date
                            {sortType.includes("Descending") ? (
                              <SortDescending className="h-5 w-5 inline-block" />
                            ) : (
                              <SortAscending className="h-5 w-5 inline-block" />
                            )}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-indigo-50">
                        <td className="px-4 py-3.5 flex justify-center items-center">
                          <label htmlFor="search-field" className="sr-only">
                            Search
                          </label>
                          <SearchIcon className="h-5 w-5 stroke-current text-indigo-600" aria-hidden="true" />
                        </td>
                        <td>
                          <input
                            id="search-field"
                            name="search-field"
                            className="block w-full h-full pr-3 py-3.5 border-transparent bg-indigo-50 text-indigo-600 placeholder-indigo-600 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm truncate"
                            placeholder="Search by Jira ID or Summary"
                            type="search"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                    {/* const gpmStartDate = data.gpmStartDate ? moment(data.gpmStartDate, "DD MM YYYY").format("DD MMM YYYY") : null; */}

                    {filteredData.length !== 0 ? (
                      filteredData.map((data) => {
                        const gpmStartDate = data.gpmStartDate ? moment(data.gpmStartDate, "DD MM YYYY").format("DD MMM YYYY") : null;
                        return (
                          <tbody key={data.id} className="bg-white divide-y divide-gray-200">
                            <tr className={data.colorStyle}>
                              <td className="text-yellow-500 px-2 sm:px-4 py-4 text-left font-semibold whitespace-nowrap text-sm">
                                <a href={`${jiraUrl}/${data.id}`} className="" target="_blank" title={data.jiraLink?.label}>
                                  <span>{data.id}</span>
                                </a>
                              </td>
                              <td className={`${data.textStyle} max-w-0 w-full px-2 sm:px-4 py-4 whitespace-nowrap text-sm`}>
                                <div className="flex flex-col lg:flex-row">
                                  <a
                                    href={`${jiraUrl}/${data.id}`}
                                    className="group inline-flex space-x-2 truncate text-sm"
                                    target="_blank"
                                    title={data.jiraLink?.label}
                                  >
                                    <p className={`${data.textStyle} truncate group-hover:text-gray-900`}>{data.name}</p>
                                  </a>
                                  <span
                                    className={classNames(
                                      statusStyles[data.status] || statusStyles.Other,
                                      "inline-flex items-center px-1 sm:px-2.5 py-0.5 rounded-full text-xs font-medium uppercase lg:hidden mt-2 max-w-max"
                                    )}
                                  >
                                    {data.status}
                                  </span>
                                  <span className="md:hidden mt-2">
                                    {data.labels?.includes("probono") ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 fill-current text-blue-500 ml-auto"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                      >
                                        <title>Pro Bono</title>
                                        <path
                                          fillRule="evenodd"
                                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    ) : (
                                      <span className="text-gray-900 font-medium">
                                        {data.amount && data.amount > 0 ? formatter.format(data.amount) : "$0"}
                                      </span>
                                    )}
                                  </span>
                                </div>
                              </td>
                              <td className="hidden md:table-cell px-4 py-4 text-right whitespace-nowrap text-sm text-gray-500 flex justify-end">
                                {data.labels?.includes("probono") ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 fill-current text-blue-500 ml-auto"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <title>Pro Bono</title>
                                    <path
                                      fillRule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                ) : (
                                  <span className="text-gray-900 font-medium">
                                    {data.amount && data.amount > 0 ? formatter.format(data.amount) : "$0"}
                                  </span>
                                )}
                              </td>
                              <td className="hidden px-4 py-4 whitespace-nowrap text-sm text-gray-500 lg:table-cell text-center">
                                <span
                                  className={classNames(
                                    statusStyles[data.status] || statusStyles.Other,
                                    "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium uppercase"
                                  )}
                                >
                                  {data.status}
                                </span>
                              </td>
                              <td className={`${data.textStyle} px-2 sm:px-4 py-4 text-right whitespace-nowrap text-xs sm:text-sm`}>
                                {/* <time dateTime={data.date}>{moment(data.date).format("DD MMM YYYY")}</time> */}
                                <time dateTime={gpmStartDate ? gpmStartDate : ""}>{gpmStartDate ? gpmStartDate : ""}</time>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })
                    ) : (
                      <tbody className="shadow-sm">
                        <tr className="bg-white">
                          <td className="px-4 py-2 flex flex-grow-0 justify-center items-center">
                            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                          </td>
                          <td className="w-full">
                            <div className="ml-3">
                              <h3 className="text-sm font-medium text-red-800 uppercase">
                                <span>No search results found</span>
                              </h3>
                            </div>
                          </td>
                          <td style={{ minWidth: "68px" }}></td>
                          <td style={{ minWidth: "176px" }}></td>
                          <td style={{ minWidth: "100px" }}></td>
                        </tr>
                      </tbody>
                    )}
                    <tbody className="shadow-sm">
                      <tr className="bg-white">
                        <td className="px-4 py-2 flex flex-grow-0 justify-center items-center">
                          <h3 className="text-sm font-medium text-red-800 uppercase">
                            <span>Total</span>
                          </h3>
                        </td>
                        <td className="w-full">
                          <div className="ml-3 ">
                            <h3 className="text-sm font-medium text-red-800 uppercase">
                              <span>{`${filteredData.length} tickets`}</span>
                            </h3>
                          </div>
                        </td>
                        <td className="hidden md:table-cell px-4 text-right whitespace-nowrap text-sm text-gray-500 flex justify-end">
                          <span className="text-gray-900 font-medium">{formatter.format(totalCost)}</span>
                        </td>
                        <td style={{ minWidth: "176px" }}></td>
                        <td style={{ minWidth: "100px" }}></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* client ledger */}
          <aside className="mt-8 md:mt-0 md:pl-4">
            {/* top ups */}
            <AsideCard heading="Top Ups" data={ledgerDataTopUps} ledgerLoaded={ledgerLoaded} />
            {/* gpm management fee */}
            {gpmManaged && <AsideCard heading="GPM Management Fee" data={ledgerDataGPMManagement} ledgerLoaded={ledgerLoaded} />}
            {/* ad management */}
            <AsideCard heading="Ad Management" data={ledgerDataAdManagement} ledgerLoaded={ledgerLoaded} />
            {/* eom balance */}
            <AsideCard heading="EOM Balance" data={ledgerDataEomBalance} ledgerLoaded={ledgerLoaded} />
            {/* project / other */}
            <AsideCard heading="Project/Other" data={ledgerDataOther} ledgerLoaded={ledgerLoaded} />
          </aside>
        </div>
      </div>
    </main>
  );
};

export default Table;
