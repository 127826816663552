import React from 'react'
import { formatter } from '../helpers/formatMoney.js';

const CardGrid = ({ mainCards, subCards }) => {
  const gridStyle = (card) => {
    let style;
    switch(card.length) {
      case 1:
        return style = 'sm:grid-cols-1'
      case 2:
        return style = 'sm:grid-cols-2'
      default:
        return style = 'sm:grid-cols-3'
    }
  }

    return (
      <div>
        <dl className={`mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:divide-x ${gridStyle(mainCards)}`}>
        {mainCards.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-medium text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div className="flex items-baseline text-3xl font-semibold text-indigo-600">
                {formatter.format(item.value)}
              </div>
            </dd>
          </div>
        ))}
        </dl>
        <div>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-4">
            {subCards.map((item) => (
              <div key={item.name} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                <dd className="mt-1 text-2xl font-semibold text-gray-900">{formatter.format(item.value)}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    )
}

export default CardGrid