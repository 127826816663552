import React, { useContext, useEffect, useState } from "react";
import { State, Dispatch } from "../providers/state";
import CardGrid from "../components/CardGrid";
import Table from "../components/Table";
import {
  dateDescendingNormal,
  dateAscendingNormal,
  dateDescending,
  dateAscending,
  jiraIdDescending,
  jiraIdAscending,
} from "../helpers/sortDate";

const IndexPage = () => {
  const state = useContext(State);
  const dispatch = useContext(Dispatch);

  const [sortType, setSortType] = useState("dateDescending");
  const [ticketsDataData, setTicketsDataData] = useState([]);
  const [ledgerDataGPMManagement, setLedgerDataGPMManagement] = useState([]);
  const [ledgerDataAdManagement, setLedgerDataAdManagement] = useState([]);
  const [ledgerDataTopUps, setLedgerDataTopUps] = useState([]);
  const [ledgerDataEomBalance, setLedgerDataEomBalance] = useState([]);
  const [ledgerDataOther, setLedgerDataOther] = useState([]);

  const [mainCards, setMainCards] = useState([]);
  const [subCards, setSubCards] = useState([]);

  useEffect(() => {
    if (state.clientData) {
      const filteredBillings = Object.entries(state.clientData)
        // .filter(([key, value]) => !isNaN(value) && value !== 0)
        .map(([key, value]) => ({ name: key, value }));
      let mainCardsArray = filteredBillings.filter(({ name }) => name === "Remaining Balance" || name === "Quoted Pending Approval");
      let subCardsArray = filteredBillings
        .filter(
          ({ name }) =>
            name === "Active & Completed GPM Work" ||
            name === "Ad Management Fees" ||
            name === "Additional Credit" ||
            name === "Invoiced Topups"
        )
        .map((card) => {
          if (card.name === "Invoiced Topups") {
            card.name = "Paid Topups";
          } else if (card.name === "Additional Credit") {
            card.name = "Bonus Credit";
          }
          return card;
        });
      setMainCards(mainCardsArray)
      setSubCards(subCardsArray)
    }

  }, [state.clientData]);

  useEffect(() => {
    if (state.appLoaded === true) {
      console.log("STATE DATA:", state);
    }
  }, [state.appLoaded]);

  useEffect(() => {
    dispatch({ route: "/" });
  }, []);

  useEffect(() => {
    console.log(state.ledgerData);
    if (state.ledgerData.adManage?.length > 0) {
      setLedgerDataAdManagement(
        state.ledgerData.adManage
          ?.map(({ ID, Type, Date, Amount, Notes, ["Additional Credit"]: adCredit }) => {
            return {
              id: ID,
              name: Type,
              date: Date,
              amount: Amount,
              colorStyle: "bg-white",
              textStyle: "text-gray-800",
              notes: Notes,
              adCredit,
            };
          })
          .sort(dateDescendingNormal)
      );
    }
    if (state.gpmManaged && state.ledgerData.gpmManage?.length > 0) {
      setLedgerDataGPMManagement(
        state.ledgerData.gpmManage
          ?.map(({ ID, Type, Date, Amount, Notes, ["Additional Credit"]: adCredit }) => {
            return {
              id: ID,
              name: Type,
              date: Date,
              amount: Amount,
              colorStyle: "bg-white",
              textStyle: "text-gray-800",
              notes: Notes,
              adCredit,
            };
          })
          .sort(dateDescendingNormal)
      );
    }
    if (state.ledgerData.topups?.length > 0) {
      console.log(state.ledgerData);
      setLedgerDataTopUps(
        state.ledgerData.topups
          ?.map(({ ID, Type, Date, Amount, Notes, ["Additional Credit"]: adCredit }) => {
            return {
              id: ID,
              name: Type,
              date: Date,
              amount: Amount,
              colorStyle: "bg-white",
              textStyle: "text-gray-800",
              notes: Notes,
              adCredit,
            };
          })
          .sort(dateDescendingNormal)
      );
    }
    if (state.ledgerData.eomBalance?.length > 0) {
      setLedgerDataEomBalance(
        state.ledgerData.eomBalance
          ?.map(({ ID, Type, Date, Amount, Notes }) => {
            return {
              id: ID,
              name: Type,
              date: Date,
              amount: Amount,
              colorStyle: "bg-white",
              textStyle: "text-gray-800",
              notes: Notes,
            };
          })
          .sort(dateDescendingNormal)
      );
    }
    if (state.ledgerData.other?.length > 0) {
      setLedgerDataOther(
        state.ledgerData.other
          ?.map(({ ID, Type, Date, Amount, Notes }) => {
            return {
              id: ID,
              name: Type,
              date: Date,
              amount: Amount,
              colorStyle: "bg-white",
              textStyle: "text-gray-800",
              notes: Notes,
            };
          })
          .sort(dateDescendingNormal)
      );
    }
  }, [state.ledgerData]);

  const sortTickets = () => {
    const types = {
      dateDescending, // default sort by recent date
      dateAscending,
      jiraIdAscending,
      jiraIdDescending,
    };
    const sortProperty = types[sortType];

    let sortedTickets = state.ticketsData.tickets
      ?.filter(({ ["GPM Start Date"]: gpmStartDate }) => {
        // filter out tickets that has gpm start date
        return gpmStartDate;
      })
      .map(
        ({ ["Issue Key"]: id, Summary, Status, Cost, Updated, ["Open in Jira"]: jiraLink, Labels, ["GPM Start Date"]: gpmStartDate }) => {
          return {
            id,
            name: Summary,
            date: Updated,
            amount: Cost,
            status: Status,
            jiraLink,
            labels: Labels,
            colorStyle: "bg-white",
            textStyle: "text-gray-800",
            gpmStartDate,
          };
        }
      )

      .sort(sortProperty);

    setTicketsDataData(sortedTickets);
  };

  useEffect(() => {
    if (state.ticketsData.tickets?.length > 0) {
      // console.log("ASDGFASD");
      console.log(state.ticketsData);
      sortTickets();
    }
  }, [state.ticketsData, sortType]);


  useEffect(() => {
    console.log(state)
  }, [state])

  return (
    <div id="dashboard" className={``}>
      <CardGrid mainCards={mainCards} subCards={subCards} />
      <Table
        loaded={state.ticketsLoaded}
        data={ticketsDataData}
        toggleButton={setSortType}
        sortType={sortType}
        gpmManaged={state.gpmManaged}
        ledgerLoaded={state.ledgerLoaded}
        ledgerDataTopUps={ledgerDataTopUps}
        ledgerDataGPMManagement={ledgerDataGPMManagement}
        ledgerDataAdManagement={ledgerDataAdManagement}
        ledgerDataEomBalance={ledgerDataEomBalance}
        ledgerDataOther={ledgerDataOther}
      />
    </div>
  );
};
export default IndexPage;
