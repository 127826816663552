import React, { useState, useEffect } from "react";
import { formatter } from "../helpers/formatMoney.js";
import moment from "moment";
import AsideSkeleton from "./AsideSkeleton";
import { Disclosure } from "@headlessui/react";

const AsideCard = ({ heading, data, ledgerLoaded, type }) => {
  const [asideData, setAsideData] = useState(null);

  if (!ledgerLoaded) {
    return <AsideSkeleton />;
  }
  // if ((ledgerLoaded && data?.length === 0) || (ledgerLoaded && data === undefined)) return null;

  const renderDetails = () => {
    if ((ledgerLoaded && data?.length === 0) || (ledgerLoaded && data === undefined)) {

      return <div role="list" className="max-h-80 overflow-y-scroll divide-y divide-gray-200">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className={`px-4 py-4 bg-white w-full hover:bg-indigo-50`} >
                <div className="flex justify-between">
                  <div className="flex items-center justify-between">
                    <div className={`text-sm font-medium text-grey-800 truncate flex-grow`}>N/A</div>
                  </div>
                </div>
              </Disclosure.Button>
            </>
          )}
        </Disclosure>
      </div>
    }
    return <div role="list" className="max-h-80 overflow-y-scroll divide-y divide-gray-200">
      {data?.map(({ id, date, amount, colorStyle, textStyle, notes, adCredit }) => (
        <Disclosure key={id}>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`px-4 py-4 ${colorStyle} w-full ${notes || adCredit ? "hover:bg-indigo-50" : "cursor-default pointer-events-none"
                  } ${open ? "bg-indigo-50" : ""}`}
              >
                <div className="flex justify-between">
                  <div className="flex items-center justify-between">
                    <div className={`text-sm font-medium ${textStyle} truncate flex-grow`}>{formatter.format(amount)}</div>
                    {/* tooltip */}
                    {(notes || (adCredit !== undefined && adCredit !== 0)) && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mx-1.5 h-5 w-5 stroke-current text-indigo-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    )}
                  </div>
                  <div className="lg:ml-2 flex-shrink-0 flex justify-end">
                    <div className="px-2 text-right inline-flex text-sm text-gray-700 leading-5 rounded-full">
                      {moment(date).format("MMM YYYY")}
                    </div>
                  </div>
                </div>
              </Disclosure.Button>
              {(notes || adCredit !== 0) && (
                <Disclosure.Panel className="text-sm leading-snug text-indigo-600 bg-indigo-50 px-4 py-4">
                  {notes}
                  {adCredit && `Additional credit: ${formatter.format(adCredit)}`}
                </Disclosure.Panel>
              )}
            </>
          )}
        </Disclosure>
      ))}
    </div>

  }

  return (
    <div className="align-middle min-w-full shadow mb-8">
      <h3 className="px-4 py-4 text-base border-b border-gray-200 leading-6 rounded-t-lg font-extrabold uppercase bg-indigo-800 text-white">
        {heading}
      </h3>
      {renderDetails()}
    </div>
  );
};

export default AsideCard;
