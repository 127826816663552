import React from "react";

const AsideSkeleton = () => {
  return (
    <div className="animate-pulse align-middle min-w-full shadow mb-8">
      <h3 className="px-4 py-4 border-b border-gray-200 rounded-t-lg bg-indigo-300">
        <div className="h-6"></div>
      </h3>
      <ul role="list" className="divide-y divide-gray-200">
        <li>
          <div className="px-4 py-4">
            <div className="flex justify-between">
              <div className="flex items-center justify-between w-full">
                <div className="h-4 bg-gray-300 rounded w-1/2"></div>
              </div>
              <div className="lg:ml-2 flex-shrink-0 flex justify-end">
                <div className="px-2 inline-flex rounded-full bg-gray-300">
                  <div className="h-4 w-5"></div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="px-4 py-4">
            <div className="flex justify-between">
              <div className="flex items-center justify-between w-full">
                <div className="h-4 bg-gray-300 rounded w-1/2"></div>
              </div>
              <div className="lg:ml-2 flex-shrink-0 flex justify-end">
                <div className="px-2 inline-flex rounded-full bg-gray-300">
                  <div className="h-4 w-5"></div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="px-4 py-4">
            <div className="flex justify-between">
              <div className="flex items-center justify-between w-full">
                <div className="h-4 bg-gray-300 rounded w-1/2"></div>
              </div>
              <div className="lg:ml-2 flex-shrink-0 flex justify-end">
                <div className="px-2 inline-flex rounded-full bg-gray-300">
                  <div className="h-4 w-5"></div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="px-4 py-4">
            <div className="flex justify-between">
              <div className="flex items-center justify-between w-full">
                <div className="h-4 bg-gray-300 rounded w-1/2"></div>
              </div>
              <div className="lg:ml-2 flex-shrink-0 flex justify-end">
                <div className="px-2 inline-flex rounded-full bg-gray-300">
                  <div className="h-4 w-5"></div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default AsideSkeleton;
