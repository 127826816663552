import React from 'react'
import AsideCard from './AsideCard';

const SkeletonLoader = ({ ledgerDataTopUps, ledgerDataAdManagement, ledgerDataGPMManagement, ledgerLoaded }) => {
    return (
        <main className="flex-1 relative overflow-y-auto focus:outline-none">
            <div className="py-8 xl:py-10">
                <div className="md:grid md:grid-cols-4">
                    {/* jira tickets */}
                    <div className="md:col-span-3 md:pr-1">
                        <div className="">
                            <div className="flex flex-col">
                                <div className="align-middle min-w-full shadow sm:rounded-lg">

                                    <table className="animate-pulse min-w-full divide-y divide-gray-100">
                                        <thead>
                                            <tr>
                                                <th className="px-2 sm:px-6 py-6 bg-yellow-100"></th>
                                                <th className="px-2 sm:px-6 py-6 bg-yellow-100"></th>
                                                <th className="hidden md:table-cell px-6 py-6 bg-yellow-100"></th>
                                                <th className="hidden px-6 py-6 bg-yellow-100 lg:table-cell"></th>
                                                <th className="px-2 sm:px-6 py-6 bg-yellow-100"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="bg-indigo-50">
                                                <td className="px-4 py-3.5 flex justify-center items-center">
                                                    <div className="h-4 w-5 bg-indigo-200 rounded"></div>
                                                </td>
                                                <td>
                                                    <div className="w-full mx-2 sm:mx-4 h-4 bg-indigo-200 rounded"></div>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                        <tbody className="bg-white divide-y divide-gray-50">
                                            <tr>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-yellow-100 rounded"></div>
                                                </td>
                                                <td className="max-w-0 w-full px-2 sm:px-4 py-4">
                                                    <div className="flex flex-col lg:flex-row">
                                                        <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                        <div
                                                            className="w-1/4 h-4 bg-green-100 inline-flex items-center px-1 sm:px-2.5 py-0.5 rounded-full lg:hidden mt-2">
                                                        </div>
                                                        <span className="w-min md:hidden mt-2"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="hidden md:table-cell px-4 py-4 flex justify-end">
                                                    <span className="font-medium"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div></span>
                                                </td>
                                                <td className="hidden px-4 py-4 lg:table-cell">
                                                    <span
                                                        className="inline-flex items-center bg-green-100 h-4 px-2.5 py-0.5 rounded-full">

                                                    </span>
                                                </td>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-yellow-100 rounded"></div>
                                                </td>
                                                <td className="max-w-0 w-full px-2 sm:px-4 py-4">
                                                    <div className="flex flex-col lg:flex-row">
                                                        <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                        <div
                                                            className="w-1/4 h-4 bg-green-100 inline-flex items-center px-1 sm:px-2.5 py-0.5 rounded-full lg:hidden mt-2">
                                                        </div>
                                                        <span className="w-min md:hidden mt-2"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="hidden md:table-cell px-4 py-4 flex justify-end">
                                                    <span className="font-medium"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div></span>
                                                </td>
                                                <td className="hidden px-4 py-4 lg:table-cell">
                                                    <span
                                                        className="inline-flex items-center bg-green-100 h-4 px-2.5 py-0.5 rounded-full">

                                                    </span>
                                                </td>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-yellow-100 rounded"></div>
                                                </td>
                                                <td className="max-w-0 w-full px-2 sm:px-4 py-4">
                                                    <div className="flex flex-col lg:flex-row">
                                                        <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                        <div
                                                            className="w-1/4 h-4 bg-green-100 inline-flex items-center px-1 sm:px-2.5 py-0.5 rounded-full lg:hidden mt-2">
                                                        </div>
                                                        <span className="w-min md:hidden mt-2"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="hidden md:table-cell px-4 py-4 flex justify-end">
                                                    <span className="font-medium"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div></span>
                                                </td>
                                                <td className="hidden px-4 py-4 lg:table-cell">
                                                    <span
                                                        className="inline-flex items-center bg-green-100 h-4 px-2.5 py-0.5 rounded-full">

                                                    </span>
                                                </td>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-yellow-100 rounded"></div>
                                                </td>
                                                <td className="max-w-0 w-full px-2 sm:px-4 py-4">
                                                    <div className="flex flex-col lg:flex-row">
                                                        <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                        <div
                                                            className="w-1/4 h-4 bg-green-100 inline-flex items-center px-1 sm:px-2.5 py-0.5 rounded-full lg:hidden mt-2">
                                                        </div>
                                                        <span className="w-min md:hidden mt-2"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="hidden md:table-cell px-4 py-4 flex justify-end">
                                                    <span className="font-medium"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div></span>
                                                </td>
                                                <td className="hidden px-4 py-4 lg:table-cell">
                                                    <span
                                                        className="inline-flex items-center bg-green-100 h-4 px-2.5 py-0.5 rounded-full">

                                                    </span>
                                                </td>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-yellow-100 rounded"></div>
                                                </td>
                                                <td className="max-w-0 w-full px-2 sm:px-4 py-4">
                                                    <div className="flex flex-col lg:flex-row">
                                                        <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                        <div
                                                            className="w-1/4 h-4 bg-green-100 inline-flex items-center px-1 sm:px-2.5 py-0.5 rounded-full lg:hidden mt-2">
                                                        </div>
                                                        <span className="w-min md:hidden mt-2"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="hidden md:table-cell px-4 py-4 flex justify-end">
                                                    <span className="font-medium"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div></span>
                                                </td>
                                                <td className="hidden px-4 py-4 lg:table-cell">
                                                    <span
                                                        className="inline-flex items-center bg-green-100 h-4 px-2.5 py-0.5 rounded-full">

                                                    </span>
                                                </td>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-yellow-100 rounded"></div>
                                                </td>
                                                <td className="max-w-0 w-full px-2 sm:px-4 py-4">
                                                    <div className="flex flex-col lg:flex-row">
                                                        <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                        <div
                                                            className="w-1/4 h-4 bg-green-100 inline-flex items-center px-1 sm:px-2.5 py-0.5 rounded-full lg:hidden mt-2">
                                                        </div>
                                                        <span className="w-min md:hidden mt-2"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="hidden md:table-cell px-4 py-4 flex justify-end">
                                                    <span className="font-medium"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div></span>
                                                </td>
                                                <td className="hidden px-4 py-4 lg:table-cell">
                                                    <span
                                                        className="inline-flex items-center bg-green-100 h-4 px-2.5 py-0.5 rounded-full">

                                                    </span>
                                                </td>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-yellow-100 rounded"></div>
                                                </td>
                                                <td className="max-w-0 w-full px-2 sm:px-4 py-4">
                                                    <div className="flex flex-col lg:flex-row">
                                                        <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                        <div
                                                            className="w-1/4 h-4 bg-green-100 inline-flex items-center px-1 sm:px-2.5 py-0.5 rounded-full lg:hidden mt-2">
                                                        </div>
                                                        <span className="w-min md:hidden mt-2"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="hidden md:table-cell px-4 py-4 flex justify-end">
                                                    <span className="font-medium"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div></span>
                                                </td>
                                                <td className="hidden px-4 py-4 lg:table-cell">
                                                    <span
                                                        className="inline-flex items-center bg-green-100 h-4 px-2.5 py-0.5 rounded-full">

                                                    </span>
                                                </td>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-yellow-100 rounded"></div>
                                                </td>
                                                <td className="max-w-0 w-full px-2 sm:px-4 py-4">
                                                    <div className="flex flex-col lg:flex-row">
                                                        <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                        <div
                                                            className="w-1/4 h-4 bg-green-100 inline-flex items-center px-1 sm:px-2.5 py-0.5 rounded-full lg:hidden mt-2">
                                                        </div>
                                                        <span className="w-min md:hidden mt-2"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="hidden md:table-cell px-4 py-4 flex justify-end">
                                                    <span className="font-medium"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div></span>
                                                </td>
                                                <td className="hidden px-4 py-4 lg:table-cell">
                                                    <span
                                                        className="inline-flex items-center bg-green-100 h-4 px-2.5 py-0.5 rounded-full">

                                                    </span>
                                                </td>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-yellow-100 rounded"></div>
                                                </td>
                                                <td className="max-w-0 w-full px-2 sm:px-4 py-4">
                                                    <div className="flex flex-col lg:flex-row">
                                                        <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                        <div
                                                            className="w-1/4 h-4 bg-green-100 inline-flex items-center px-1 sm:px-2.5 py-0.5 rounded-full lg:hidden mt-2">
                                                        </div>
                                                        <span className="w-min md:hidden mt-2"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="hidden md:table-cell px-4 py-4 flex justify-end">
                                                    <span className="font-medium"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div></span>
                                                </td>
                                                <td className="hidden px-4 py-4 lg:table-cell">
                                                    <span
                                                        className="inline-flex items-center bg-green-100 h-4 px-2.5 py-0.5 rounded-full">

                                                    </span>
                                                </td>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-yellow-100 rounded"></div>
                                                </td>
                                                <td className="max-w-0 w-full px-2 sm:px-4 py-4">
                                                    <div className="flex flex-col lg:flex-row">
                                                        <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                        <div
                                                            className="w-1/4 h-4 bg-green-100 inline-flex items-center px-1 sm:px-2.5 py-0.5 rounded-full lg:hidden mt-2">
                                                        </div>
                                                        <span className="w-min md:hidden mt-2"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="hidden md:table-cell px-4 py-4 flex justify-end">
                                                    <span className="font-medium"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div></span>
                                                </td>
                                                <td className="hidden px-4 py-4 lg:table-cell">
                                                    <span
                                                        className="inline-flex items-center bg-green-100 h-4 px-2.5 py-0.5 rounded-full">

                                                    </span>
                                                </td>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-yellow-100 rounded"></div>
                                                </td>
                                                <td className="max-w-0 w-full px-2 sm:px-4 py-4">
                                                    <div className="flex flex-col lg:flex-row">
                                                        <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                        <div
                                                            className="w-1/4 h-4 bg-green-100 inline-flex items-center px-1 sm:px-2.5 py-0.5 rounded-full lg:hidden mt-2">
                                                        </div>
                                                        <span className="w-min md:hidden mt-2"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="hidden md:table-cell px-4 py-4 flex justify-end">
                                                    <span className="font-medium"><div className="h-4 bg-gray-300 text-gray-300 text-xs rounded">$0</div></span>
                                                </td>
                                                <td className="hidden px-4 py-4 lg:table-cell">
                                                    <span
                                                        className="inline-flex items-center bg-green-100 h-4 px-2.5 py-0.5 rounded-full">

                                                    </span>
                                                </td>
                                                <td className="px-2 sm:px-4 py-4">
                                                    <div className="h-4 bg-gray-300 rounded w-full"></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* client ledger */}
                    <aside className="mt-8 md:mt-0 md:pl-4">
                        <AsideCard heading="Top Ups" data={ledgerDataTopUps} ledgerLoaded={ledgerLoaded} />

                        <div className="md:mt-7 mt-8 md:py-6 md:space-y-8">
                            <AsideCard heading="GPM MANAGEMENT FEE" data={ledgerDataGPMManagement} ledgerLoaded={ledgerLoaded} />
                        </div>

                        <div className="md:mt-7 mt-8 md:py-6 md:space-y-8">
                            <AsideCard heading="Ad Management" data={ledgerDataAdManagement} ledgerLoaded={ledgerLoaded} />
                        </div>
                    </aside>
                </div>
            </div>
        </main>

    )
}

export default SkeletonLoader;